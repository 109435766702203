import { COUNTRY_CURRENCIES, COUNTRY_SHORTS } from '~/constants'
import type { Nullable, RvSnapshot } from '~/types'

export function getRvSnapshot(rvSnapshot?: Nullable<RvSnapshot>) {
  /**
   * Basic RVSnapshot properties.
   * Always in the format of `rvSnapshot[PropertyName]`.
   * Defaults are dependent on the schema and added manually.
   */
  const rvSnapshotAllowCrossBorderTravel = rvSnapshot?.AllowCrossBorderTravel ?? false
  const rvSnapshotChargePerHourOver = rvSnapshot?.ChargePerHourOver ?? 0
  const rvSnapshotCheckInPreference = rvSnapshot?.CheckInPreference ?? null
  const rvSnapshotCheckOutPreference = rvSnapshot?.CheckOutPreference ?? null
  const rvSnapshotCity = rvSnapshot?.City ?? null
  const rvSnapshotDailyKMAllowed = rvSnapshot?.DailyKMAllowed ?? 0
  const rvSnapshotDeliveryCentsPerDistanceUnit = rvSnapshot?.DeliveryCentsPerDistanceUnit ?? null
  const rvSnapshotDeliveryMaxDistance = rvSnapshot?.DeliveryMaxDistance ?? null
  const rvSnapshotDeliveryMinimumCostCents = rvSnapshot?.DeliveryMinimumCostCents ?? null
  const rvSnapshotFreeHoursPerDay = rvSnapshot?.FreeHoursPerDay ?? 0
  const rvSnapshotHasSmartPricingApplied = rvSnapshot?.HasSmartPricingApplied ?? false
  const rvSnapshotId = rvSnapshot?.Id
  const rvSnapshotIsChargeGenerator = rvSnapshot?.IsChargeGenerator ?? false
  const rvSnapshotIsChargeMileage = rvSnapshot?.IsChargeMileage ?? false
  const rvSnapshotIsDeliveryOnly = rvSnapshot?.IsDeliveryOnly ?? false
  const rvSnapshotIsInsured = rvSnapshot?.IsInsured ?? false
  const rvSnapshotMinimumRentalDay = rvSnapshot?.MinimumRentalDay ?? 1
  const rvSnapshotOriginalDefaultPrice = rvSnapshot?.OriginalDefaultPrice ?? 0
  const rvSnapshotRentalType = rvSnapshot?.RentalType
  const rvSnapshotRVState = rvSnapshot?.RVState ?? null
  const rvSnapshotRVType = rvSnapshot?.RVType ?? null
  const rvSnapshotSecurityDepositAmount = rvSnapshot?.SecurityDepositAmount ?? 0
  const rvSnapshotSmartPricingMinPrice = rvSnapshot?.SmartPricingMinPrice ?? 0
  const rvSnapshotSmartPricingPercentage = rvSnapshot?.SmartPricingPercentage ?? 0
  const rvSnapshotSurchargePerExtraKM = rvSnapshot?.SurchargePerExtraKM ?? 0

  /**
   * Advanced properties that depend on more than just the base properties.
   * Always in the format of `rvSnapshot[CustomPropertyName]`.
   */
  const rvSnapshotCountry = rvSnapshot?.Country ? (COUNTRY_SHORTS[rvSnapshot?.Country as keyof typeof COUNTRY_SHORTS]) : undefined
  const rvSnapshotCurrency = rvSnapshotCountry && COUNTRY_CURRENCIES[rvSnapshotCountry]
  const rvSnapshotConvertedDailyKMAllowed = rvSnapshotCountry === 'US' ? miToKm(rvSnapshotDailyKMAllowed) : rvSnapshotDailyKMAllowed
  const rvSnapshotFreeDistanceKmsPerDay = rvSnapshotIsChargeMileage ? Math.round(rvSnapshotConvertedDailyKMAllowed) : 0
  const rvSnapshotHasPerUnitDelivery = Boolean(rvSnapshotDeliveryMaxDistance && rvSnapshotDeliveryCentsPerDistanceUnit && rvSnapshotDeliveryMinimumCostCents)
  const rvSnapshotMeasurementUnit = countryCodeToDistanceUnit(rvSnapshotCountry)
  /**
   * Advanced `has` checks.
   */
  const rvSnapshotHasDelivery = rvSnapshotHasPerUnitDelivery
  const rvSnapshotHasFreeHoursPerDay = Boolean(rvSnapshotFreeHoursPerDay > 0)
  const rvSnapshotHasSmartPricing = Boolean(rvSnapshotSmartPricingPercentage || rvSnapshotSmartPricingMinPrice)

  /**
   * Advanced `is` checks.
   */
  const rvSnapshotIsChargeGeneratorEnabled = Boolean(rvSnapshotFreeHoursPerDay >= 0 && rvSnapshotChargePerHourOver > 0)
  const rvSnapshotIsChargeMileageWithSomeIncluded = Boolean(rvSnapshotIsChargeMileage && rvSnapshotDailyKMAllowed >= 0 && rvSnapshotSurchargePerExtraKM !== 0)
  const rvSnapshotIsStatic = rvSnapshot?.RentalType === 'RVCottage'
  const rvSnapshotIsMotorized = rvSnapshot?.RentalType === 'MotorHome'
  const rvSnapshotIsTowable = rvSnapshot?.RentalType === 'TravelTrailer'

  return {
    rvSnapshotAllowCrossBorderTravel,
    rvSnapshotChargePerHourOver,
    rvSnapshotCheckInPreference,
    rvSnapshotCheckOutPreference,
    rvSnapshotCity,
    rvSnapshotCountry,
    rvSnapshotCurrency,
    rvSnapshotDailyKMAllowed,
    rvSnapshotConvertedDailyKMAllowed,
    rvSnapshotDeliveryCentsPerDistanceUnit,
    rvSnapshotDeliveryMaxDistance,
    rvSnapshotDeliveryMinimumCostCents,
    rvSnapshotFreeDistanceKmsPerDay,
    rvSnapshotFreeHoursPerDay,
    rvSnapshotHasSmartPricingApplied,
    rvSnapshotId,
    rvSnapshotIsChargeGenerator,
    rvSnapshotIsChargeMileage,
    rvSnapshotIsDeliveryOnly,
    rvSnapshotIsInsured,
    rvSnapshotMeasurementUnit,
    rvSnapshotMinimumRentalDay,
    rvSnapshotOriginalDefaultPrice,
    rvSnapshotRentalType,
    rvSnapshotRVState,
    rvSnapshotRVType,
    rvSnapshotSecurityDepositAmount,
    rvSnapshotSmartPricingMinPrice,
    rvSnapshotSmartPricingPercentage,
    rvSnapshotSurchargePerExtraKM,

    rvSnapshotHasFreeHoursPerDay,
    rvSnapshotHasPerUnitDelivery,
    rvSnapshotHasDelivery,
    rvSnapshotHasSmartPricing,

    rvSnapshotIsChargeGeneratorEnabled,
    rvSnapshotIsChargeMileageWithSomeIncluded,
    rvSnapshotIsStatic,
    rvSnapshotIsMotorized,
    rvSnapshotIsTowable,
  }
}
